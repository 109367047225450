import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button } from './Button';

export const SubmitButton = forwardRef(({ title, ...otherProps }, ref) => {
    const buttonRef = useRef();
    const [disabled, setDisabled] = useState(false);

    useImperativeHandle(ref, () => ({
        setDisabled // exposes setDisabled to the incoming ref
    }));

    return (
        <Button
            buttonRef={buttonRef}
            disabled={disabled}
            title={disabled ? title : null}
            type='submit'
            variant='solid'
            {...otherProps}
        />
    );
});

SubmitButton.displayName = 'SubmitButton';
