import { css } from 'styled-components';
import { accent0, focus } from '../../globals/colors';
import { mobileMixin } from '../../styles/mixins';
import { alpha } from '../../utility/alpha';

/* stylelint-disable no-descending-specificity */

export const defaultButtonCss = css`
    flex-grow: 0;
    flex-shrink: 0;
    padding: 1em 2em;
    border: 2px solid ${accent0};
    border-radius: .5em;
    color: white;
    text-align: center;
    font-weight: bold;

    &:focus, &:hover:not(:disabled) {
        background: ${alpha('#fff', 0.1)};
    }

    &:focus {
        border-color: ${focus};
        box-shadow: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    ${mobileMixin(css`
        font-size: 2rem;
    `)}
`;

export const solidButtonCss = css`
    ${defaultButtonCss}
    background: ${accent0};
    border: 2px solid white;

    &:hover:not(:disabled) {
        background: ${alpha(accent0, 0.8)};
    }

    &:focus {
        background: ${focus};
    }
`;
