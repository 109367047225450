import 'normalize.css';
import React from 'react';
import { ResponsiveLayout } from '../components/ResponsiveLayout';
import '../globals/fonts.css';
import '../globals/global-styles.css';
import '../globals/reset.css';
import { useStoredState } from '../hooks/useStoredState';
import { DisableImplicitFormSubmissionButton } from '../utility/DisableImplicitFormSubmissionButton';
import { GoogleApiContext, useGoogleInit } from '../utility/googleApi';
import { useGuid } from '../utility/useGuid';
import { mainLayoutWrapperCss } from './MainLayout.styles';

export function MainLayout({
    title = 'Navegate Quoting',
    onSubmit,
    backUrl,
    percent,
    addAnother,
    onSummaryPage = false,
    ...otherProps
}) {
    const { loaded } = useGoogleInit();
    const guid = useGuid();

    const {
        storedState: {
            summary
        }
    } = useStoredState();

    let Wrapper = React.Fragment;
    const wrapperProps = {};
    if (onSubmit) {
        Wrapper = MainLayoutWrapper;
        wrapperProps.onSubmit = onSubmit;
    }

    const isSummaryNavigation = summary && !onSummaryPage;

    let nextButtonText = 'Next';
    if (onSummaryPage) {
        nextButtonText = 'Request Quote';
    } else if (isSummaryNavigation) {
        nextButtonText = 'Quote Summary';
    }

    let url, percentComplete;
    if (!isSummaryNavigation) {
        url = backUrl;
        percentComplete = percent;
    }

    return (
        <Wrapper {...wrapperProps}>
            <GoogleApiContext.Provider value={{ loaded }}>
                <ResponsiveLayout
                    addAnother={addAnother && !isSummaryNavigation}
                    backUrl={url}
                    nextButtonText={nextButtonText}
                    onSubmit={onSubmit}
                    percent={percentComplete}
                    showLogo={!guid}
                    {...otherProps}
                />
            </GoogleApiContext.Provider>
        </Wrapper>
    );
}

export const MainLayoutWrapper = ({ children, onSubmit, ...otherProps }) => (
    <form
        css={mainLayoutWrapperCss}
        method='post'
        onSubmit={onSubmit}
        {...otherProps}
    >
        <DisableImplicitFormSubmissionButton />
        {children}
    </form>
);
