import { Button as ButtonGrommet } from 'grommet/components/Button';
import React, { forwardRef } from 'react';
import { defaultButtonCss, solidButtonCss } from './Button.styles';

export function Button({
    variant = 'solid',
    buttonRef,
    ...otherProps
}) {
    if (variant === 'outline') return (
        <OutlineButton
            ref={buttonRef}
            {...otherProps}
        />
    );

    if (variant === 'solid') return (
        <SolidButton
            ref={buttonRef}
            {...otherProps}
        />
    );
}

export const OutlineButton = forwardRef((props, ref) => (
    <ButtonGrommet
        css={defaultButtonCss}
        ref={ref}
        {...props}
    />
));

OutlineButton.displayName = 'OutlineButton';

export const SolidButton = forwardRef((props, ref) => (
    <ButtonGrommet
        css={solidButtonCss}
        ref={ref}
        {...props}
    />
));

SolidButton.displayName = 'SolidButton';
