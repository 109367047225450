import { css } from 'styled-components';

export const mainLayoutWrapperCss = css`
    height: 100%;
    width: 100%;
`;

export const grommetStyle = {
    position: 'relative'
};
