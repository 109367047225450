import NextLink from 'next/link';
import React, { forwardRef } from 'react';

export function Link({
    as,
    children,
    component: Component = 'a',
    href,
    passHref,
    prefetch,
    replace,
    scroll,
    shallow,
    ...otherProps
}) {
    const ComponentWithRef = forwardRef((props, ref) => (
        // because the component is dynamic and usually a functional component
        // we are unable to pass a ref to it, and so we are eating the ref here
        <Component {...props} />
    ));

    ComponentWithRef.displayName = 'Link_ComponentWithRef';

    return (
        <NextLink
            as={as}
            href={href}
            passHref={passHref}
            prefetch={prefetch}
            replace={replace}
            scroll={scroll}
            shallow={shallow}
        >
            <ComponentWithRef {...otherProps}>
                {children}
            </ComponentWithRef>
        </NextLink>
    );
}
