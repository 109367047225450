import React, { cloneElement, useEffect, useRef } from 'react';
import { forwarderConfig } from '../../globals/ForwarderConfig';
import { Button } from '../Button';
import { LabeledCheckbox } from '../LabeledCheckbox';
import { Link } from '../Link';
import { ProgressBar } from '../ProgressBar';
import { SubmitButton } from '../SubmitButton';
import {
    footerCss,
    headerFooterMixin,
    labeledCheckboxCss,
    logoCss,
    mainCss,
    progressCss,
    rootCss
} from './ResponsiveLayout.styles';

/**
 * Sets the height of the `<html>` element to the viewport height.
 * On some mobile browsers, 100vh is bigger than the bounds of the viewport.
 * @link https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
function setHtmlHeightBasedOnViewport() {
    setTimeout(() => {
        document.documentElement.style.setProperty('height', `${window.innerHeight}px`);
    }, 10);
}

export function ResponsiveLayout({
    addAnother,
    backUrl,
    banner,
    buttonTitle,
    children,
    hideNextButton,
    nextButtonText,
    onSubmit,
    percent,
    showLogo,
    ...otherProps
}) {

    useEffect(() => {
        setHtmlHeightBasedOnViewport();
        window.addEventListener('resize', setHtmlHeightBasedOnViewport);
    }, []);

    // has a setDisabled property exposed from SubmitButton, which we pass as a prop to the children
    const submitButtonRef = useRef();

    const shouldHideHeaderAndFooter = !backUrl && !percent && hideNextButton;

    let childrenToRender;
    if (Array.isArray(children)) {
        childrenToRender = children.map((child) => child && cloneElement(child, { submitButtonRef }));
    } else {
        childrenToRender = children && cloneElement(children, { submitButtonRef });
    }

    return (
        <div
            css={rootCss}
            {...otherProps}
        >
            {banner || (
                <header
                    css={headerFooterMixin}
                    displayNone={shouldHideHeaderAndFooter}
                >
                    {showLogo && (
                        <img
                            alt={process.env.FORWARDER}
                            css={logoCss}
                            src={forwarderConfig[process.env.FORWARDER].logoSrc}
                        />
                    )}
                </header>
            )}
            <main css={mainCss}>
                {childrenToRender}
            </main>
            {!shouldHideHeaderAndFooter && (
                <footer
                    css={footerCss}
                    displayNone={shouldHideHeaderAndFooter}
                >
                    <Link
                        component={Button}
                        href={backUrl || '/'}
                        style={{ visibility: backUrl ? 'visible' : 'hidden' }}
                        variant='outline'
                    >
                        Back
                    </Link>
                    <ProgressBar
                        css={progressCss}
                        percent={percent}
                        style={{
                            visibility: percent ? 'visible' : 'hidden',
                        }}
                    />
                    <div>
                        {addAnother && (
                            <LabeledCheckbox
                                css={labeledCheckboxCss}
                                id='addAnother'
                            >
                                Add Another
                            </LabeledCheckbox>
                        )}
                        <SubmitButton
                            ref={submitButtonRef}
                            style={{
                                visibility: hideNextButton ? 'hidden' : 'visible',
                                marginLeft: addAnother ? '3rem' : ''
                            }}
                            title={buttonTitle}
                        >
                            {nextButtonText}
                        </SubmitButton>
                    </div>
                </footer>
            )}
        </div>
    );
}
