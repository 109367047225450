import pick from 'lodash/pick';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

export const storedStateKey = 'qm_store';

export function useStoredState() {
    const router = useRouter();
    const [store, setStore] = useState({ ready: false });
    const rootUrl = '/';

    const clearStoredState = useCallback(function clearStoredState() {
        sessionStorage.removeItem(storedStateKey);
        setStore({ ready: true });
    }, []);

    useEffect(() => {
        const { clearSessionStorage } = router.query;
        if (clearSessionStorage === '') { // parameter exists, but no value was assigned
            clearStoredState();
        } else {
            // sessionStorage needs to be accessed inside a useEffect.
            const sessionStorageStore = JSON.parse(sessionStorage.getItem(storedStateKey)) || {};
            setStore({ ...sessionStorageStore, ready: true });
        }
    }, [clearStoredState, router]);

    function setStoredState(partialState) {
        const newState = { ...store, ...partialState };
        setStore(newState);
        sessionStorage.setItem(storedStateKey, JSON.stringify(newState));
    }

    function keepSelectedStateProperties(keys) {
        const newState = pick(JSON.parse(sessionStorage.getItem(storedStateKey)), keys);
        setStore(newState);
        sessionStorage.setItem(storedStateKey, JSON.stringify(newState));
    }

    return {
        clearStoredState,
        keepSelectedStateProperties,
        storedState: store,
        setStoredState,
        rootUrl
    };
}
