import React from 'react';
import { accent0 } from '../../globals/colors';
import { OuterMeter } from './ProgressBar.styles';

export function ProgressBar({
    percent,
    ...otherProps
}) {
    return (
        <OuterMeter
            background="transparent"
            round={true}
            size="large"
            thickness="small"
            values={[{
                value: percent,
                color: accent0,
            }]}
            {...otherProps}
        />
    );
}
