export const fontSize = {
    xsmall: {
        smallScreen: '2rem',
        mediumScreen: '2.5rem',
        largeScreen: '3rem'
    },
    small: {
        smallScreen: '2.25rem',
        mediumScreen: '3rem',
        largeScreen: '4rem'
    },
    medium: {
        smallScreen: '3rem',
        mediumScreen: '4rem',
        largeScreen: '5rem'
    },
    large: {
        smallScreen: '4rem',
        mediumScreen: '5.5rem',
        largeScreen: '8rem'
    }
};
