/**
* Solution found at https://github.com/github/fetch/issues/175#issuecomment-216791333
* Resolving the promise if we timeout so that nothing blows up, but console error for visibility
* @param {Promise} promise the fetch promise to await / timeout
* @param {Number} ms number of milliseconds to wait until timing out
*/
export function timeoutFetch(promise, ms) {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            const errorString = `Promise timed out after ${ms} ms`;
            reject(new Error(errorString));
        }, ms);
        promise.then(
            (response) => {
                clearTimeout(timeout);
                resolve(response);
            },
            (error) => {
                clearTimeout(timeout);
                console.error(error);
                reject(error);
            }
        );
    });
}
