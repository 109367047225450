import { css } from 'styled-components';
import { gray1 } from '../../globals/colors';
import { fontSize } from '../../globals/fontSize';
import { breakpoints, mobileMixin, responsivePropertyMixin } from '../../styles/mixins';

export const labeledCheckboxCss = css`
    ${responsivePropertyMixin(breakpoints, [
        ['font-size', ...Object.values(fontSize.xsmall)]
    ])}
`;

export const rootCss = css`
    background: ${gray1};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const logoCss = css`
    ${responsivePropertyMixin(breakpoints, [
        ['height', ...Object.values(fontSize.large)]
    ])}
`;

export const responsivePadding = ['2rem', '3rem', '4rem'];
export const headerFooterMixin = css`
    flex-shrink: 0;
    ${responsivePropertyMixin(breakpoints, [
        ['padding', ...responsivePadding],
        ['height', '11.5rem', '14.5rem', '18rem'],
    ])}
`;

export const mainCss = css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${responsivePropertyMixin(breakpoints, [
        ['padding', '0 2rem', '0 14%', '0 20%']
    ])}
`;

/* eslint-disable indent */
export const footerCss = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 51rem;
    ${headerFooterMixin}

    & > *:nth-child(1) {
        position: absolute;
        ${responsivePropertyMixin(breakpoints, [
            ['font-size', ...Object.values(fontSize.xsmall)], ['left', ...responsivePadding]
        ])}
    }
    & > *:nth-child(3) {
        position: absolute;
        display: flex;
        ${responsivePropertyMixin(breakpoints, [
            ['font-size', ...Object.values(fontSize.xsmall)], ['right', ...responsivePadding]
        ])}
    }

    ${mobileMixin(css`
        padding: 2rem;
    `)}
`;
/* eslint-enable indent */

export const progressCss = css`
    ${responsivePropertyMixin(breakpoints, [
        ['margin', '0', '0 45rem', '0 50rem']
    ])}
    ${mobileMixin(css`
        display: none;
    `, 900)}
`;
