import React from 'react';

/**
 * This utility component disables implicit form submission.
 *
 * https://www.w3.org/TR/2018/SPSD-html5-20180327/forms.html#implicit-submission
 * In the above link it is described that:
 * "A form element's default button is the first submit button in tree order whose form owner is that form element."
 * and, that:
 * "Note: Consequently, if the default button is disabled, the form is not submitted when such an
 * implicit submission mechanism is used."
 *
 * In order for this button to be the default submit button and for
 * this fix to work, it must be the first submit button in tree order.
 */
export function DisableImplicitFormSubmissionButton() {
    return (
        <button
            disabled
            style={{ display: 'none' }}
            type='submit'
        />
    );
}
