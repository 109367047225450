import styled from 'styled-components';
import { responsivePropertyMixin, breakpoints } from '../styles/mixins';
import { fontSize } from '../globals/fontSize';

export const Header = styled.h1`
    font-family: Gotham;
    font-weight: bold;
    text-align: center;
    line-height: 1.25em;
    width: 100%;
    padding: 0 .5em;
    margin-bottom: 1em;
    ${responsivePropertyMixin(breakpoints, [
        ['font-size', ...Object.values(fontSize.large)]
    ])}
`;
