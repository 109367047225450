import fetch from 'isomorphic-unfetch';
import { createContext, useEffect, useState } from 'react';
import { GOOGLE_GEOCODE_API_KEY } from '../globals/env';
import { timeoutFetch } from './timeoutFetch';

const userInfoUrl = 'https://api.userinfo.io/userinfos';

export const GoogleApiContext = createContext({
    loaded: true
});

export function useGoogleInit() {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (window?.google?.maps?.places) {
            setLoaded(true);
        } else {
            /**
             * https://api.userinfo.io/userinfos was timing out on 3/31/2020, and so we now have
             * a timeout set in place to catch that call if it goes on for too long.
             */
            async function appendGoogleScript() {
                let domain = 'https://maps.googleapis.com';
                try {
                    const response = await timeoutFetch(fetch(userInfoUrl), 1500);
                    const userInfoResponse = response.json();
                    if (userInfoResponse?.country?.code === 'CN') {
                        domain = 'http://maps.google.cn';
                    }
                } catch (err) {
                    console.error(userInfoUrl, err);
                } finally {
                    window.googleLoaded = function googleLoaded() {
                        setLoaded(true);
                    };

                    const script = document.createElement('script');
                    script.src = `${domain}/maps/api/js?key=${GOOGLE_GEOCODE_API_KEY}&libraries=places&callback=googleLoaded`;
                    document.body.appendChild(script);
                }
            }
            appendGoogleScript();
        }
    }, []);

    return {
        loaded
    };
}
