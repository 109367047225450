/* stylelint-disable selector-descendant-combinator-no-non-space */
/* stylelint-disable selector-type-no-unknown */

import React from 'react';
import styled from 'styled-components';
import { accent0, focus } from '../../globals/colors';
import { visuallyHiddenMixin } from '../../styles/mixins';

export const Checkmark = styled.svg.attrs(() => ({
    viewBox: '66 156 481 481',
    children: <path d="M497 306L262 540c-6 6-17 6-23 0L114 415c-6-6-6-17 0-23l31-31c6-6 17-6 23 0l82 82 193-192c6-6 17-6 23 0l31 31C504 289 504 300 497 306L497 306z" />
}))`
    height: 1em;
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    fill: ${accent0};
`;

export const LabeledCheckboxLabel = styled.label`
    color: white;
    font-weight: 100;
    display: flex;
    align-items: center;
    white-space: nowrap;
    user-select: none;

    &::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        background: white;
        margin-right: .5em;
    }
`;

export const LabeledCheckboxRoot = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    font-size: 2rem;

    & > input[type=checkbox] {
        :focus ~ ${LabeledCheckboxLabel}::before{
            box-shadow: 0 0 0 2px ${focus};
        }

        ${visuallyHiddenMixin}

        :checked + ${Checkmark} {
            visibility: visible !important;
        }
    }
`;
