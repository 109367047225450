import React, { forwardRef } from 'react';
import { Checkmark, LabeledCheckboxLabel, LabeledCheckboxRoot } from './LabeledCheckbox.styles';

export const LabeledCheckbox = forwardRef(({
    children,
    id,
    checked,
    onChange,
    ...otherProps
}, ref) => {
    return (
        <LabeledCheckboxRoot {...otherProps}>
            <input
                checked={checked}
                id={id}
                onChange={onChange}
                ref={ref}
                type='checkbox'
            />
            <Checkmark />
            <LabeledCheckboxLabel htmlFor={id}>
                {children}
            </LabeledCheckboxLabel>
        </LabeledCheckboxRoot>
    );
});

LabeledCheckbox.displayName = 'LabeledCheckbox';
