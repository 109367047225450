import { useEffect, useState } from 'react';
import { getGuidFromCookie } from 'emerald-ui/dist/utilities/getGuidFromCookie';

/**
 * Parses the cookie string looking for the user's GUID. If it doesn't
 * exist this function returns null.
 *
 * @returns {?string} The users guid. Null if it doesn't exist
 */
export function useGuid() {
    const [guid, setGuid] = useState('guid'); // initialized to a string so that the logo doesn't flash in emerald

    useEffect(() => {
        setGuid(getGuidFromCookie());
    }, []);

    return guid;
}
